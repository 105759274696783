import React from 'react'
import styled from '@emotion/styled'

const SC: any = {}
SC.List = styled.ul`
  margin-top: 4rem;
  margin-bottom: 4rem;
  list-style-type: none;
  margin-left: 0;
`

SC.Item = styled.li<any>`
  margin-bottom: 2.5rem;

  @media (max-width: ${props => props.theme.breakpoints.s}) {
    margin-bottom: 3.5rem;
  }
`

SC.Headline = styled.p<any>`
  font-family: 'Source Sans Pro', -apple-system, 'BlinkMacSystemFont',
    'Segoe UI', 'Roboto', 'Helvetica', 'Arial', sans-serif, 'Apple Color Emoji',
    'Segoe UI Emoji', 'Segoe UI Symbol';
  color: ${props => props.theme.colors.grey};
  margin-bottom: 0;
  a {
    color: ${props => props.theme.colors.grey};
    font-style: normal;
    font-weight: normal;
  }
`

SC.Name = styled.p<any>`
  margin-top: -0.5rem;
  font-size: 2.369rem;
  color: ${props => props.theme.colors.black};
  font-style: normal;
  font-weight: 700;
  margin-bottom: 0;

  @media (max-width: ${props => props.theme.breakpoints.s}) {
    font-size: 1.777rem;
    margin-top: 0;
    margin-bottom: 0.2rem;
  }
`

SC.Desc = styled.p<any>`
  color: ${props => props.theme.colors.grey};
`

const Listing = ({ items }) => (
  <SC.List>
    {items.map((item, index) => (
      <SC.Item key={index}>
        <SC.Headline>{item.year}</SC.Headline>
        <SC.Name>{item.name}</SC.Name>
        <SC.Desc>{item.description}</SC.Desc>
      </SC.Item>
    ))}
  </SC.List>
)

export default Listing
