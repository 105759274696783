import React, { useContext } from 'react'
import { graphql } from 'gatsby'
import styled from '@emotion/styled'

import { LocaleContext } from '../components/Layout'
import SEO from '../components/SEO'
import Wrapper from '../components/Wrapper'
import Title from '../components/Title'
import Listing from '../components/Listing'
// import Image from '../components/image'

const SC: any = {}
SC.Hero = styled.header<any>`
  background-color: ${props => props.theme.colors.greyLight};
  display: flex;
  align-items: center;
`
SC.HeroInner = styled(Wrapper)<any>`
  padding-top: 13rem;
  padding-bottom: 13rem;
  h1 {
    margin-bottom: 2rem;
  }
  @media (max-width: ${props => props.theme.breakpoints.l}) {
    padding-top: 10rem;
    padding-bottom: 10rem;
  }
  @media (max-width: ${props => props.theme.breakpoints.m}) {
    padding-top: 8rem;
    padding-bottom: 8rem;
  }
  @media (max-width: ${props => props.theme.breakpoints.s}) {
    padding-top: 6rem;
    padding-bottom: 6rem;
  }
`
SC.HeroText = styled.div<any>`
  font-size: 1.7rem;
  line-height: 1.4;
  margin-bottom: 2rem;
  @media (max-width: ${props => props.theme.breakpoints.m}) {
    font-size: 1.4rem;
  }
  @media (max-width: ${props => props.theme.breakpoints.s}) {
    font-size: 1.25rem;
  }
`
SC.Social = styled.ul<any>`
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;
  margin-left: 0;
  font-family: 'Source Sans Pro', -apple-system, 'BlinkMacSystemFont',
    'Segoe UI', 'Roboto', 'Helvetica', 'Arial', sans-serif, 'Apple Color Emoji',
    'Segoe UI Emoji', 'Segoe UI Symbol';
  li {
    display: inline;
    &:not([data-name='social-entry-0']) {
      margin-left: 2.5rem;
      @media (max-width: ${props => props.theme.breakpoints.s}) {
        margin-left: 1.75rem;
      }
    }
    a {
      font-style: normal;
      color: ${props => props.theme.colors.greyDark};
      font-size: 1.333rem;
      font-weight: 600;
      &:hover,
      &:focus {
        color: ${props => props.theme.colors.primary};
        text-decoration: none;
      }
      @media (max-width: ${props => props.theme.breakpoints.s}) {
        font-size: 1.2rem;
      }
    }
  }
`

const IndexWrapper = Wrapper.withComponent('main')

const IndexPage = ({
  data: { homepage, projects },
  pageContext: { locale },
  location,
}) => {
  const lang = useContext(LocaleContext)
  const i18n = lang.i18n[lang.locale]

  return (
    <>
      <SEO pathname={location.pathname} locale={locale} />
      <SC.Hero>
        <SC.HeroInner>
          <h1>{homepage.data.title.text}</h1>
          <SC.HeroText
            dangerouslySetInnerHTML={{ __html: homepage.data.content.html }}
          />
          <SC.Social>
            {homepage.data.links.map((l, index) => (
              <li data-name={`social-entry-${index}`} key={index}>
                <a href={l.link.url}>{l.label}</a>
              </li>
            ))}
          </SC.Social>
        </SC.HeroInner>
      </SC.Hero>
      <IndexWrapper style={{ paddingTop: '2rem', paddingBottom: '2rem' }}>
        <Title style={{ marginTop: '4rem' }}>{i18n.projects}</Title>
        <Listing items={projects.data.body[0].items} />
      </IndexWrapper>
    </>
  )
}

export default IndexPage

// const IndexPage = () => (
//   <>
//     <SEO title="Home" />
//     <h1>Hi people</h1>
//     <p>Welcome to your new Gatsby site.</p>
//     <p>Now go build something great.</p>
//     <div style={{ maxWidth: `300px`, marginBottom: `1.45rem` }}>
//       <Image />
//     </div>
//     <Link to="/page-2/">Go to page 2</Link>
//   </>
// )

export const pageQuery = graphql`
  query IndexQuery($locale: String!) {
    homepage: prismicHomepage(lang: { eq: $locale }) {
      data {
        title {
          text
        }
        content {
          html
        }
        links {
          label
          link {
            url
          }
        }
      }
    }
    projects: prismicProjects(lang: { eq: $locale }) {
      data {
        body {
          ... on PrismicProjectsBodyProject {
            items {
              name
              year
              description
            }
          }
        }
      }
    }
  }
`
